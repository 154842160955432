import { defineMessages } from "react-intl";

export const {
         loading,
         notFound,
         updateButtonLabel,
         deleteButtonLabel,
         updateTitle,
         deleteTitle,
         yesLabel,
         noLabel,
         areYouSure,
         title,
         addButtonLabel,
         addTitle,
         domainLabel,
         domainValidation,
       } = defineMessages({
         updateButtonLabel: {
           defaultMessage: "Update",
           id: "sideBar.updateButtonLabel",
         },
         loading: {
           defaultMessage: "Loading...",
           id: "sideBar.loading",
         },
         notFound: {
           defaultMessage: "There are no domains",
           id: "sideBar.notFound",
         },
         deleteButtonLabel: {
           defaultMessage: "Delete",
           id: "sideBar.deleteButtonLabel",
         },
         updateTitle: {
           defaultMessage: "Update domain",
           id: "sideBar.updateTitle",
         },
         deleteTitle: {
           defaultMessage: "Delete domain",
           id: "sideBar.deleteTitle",
         },
         yesLabel: {
           defaultMessage: "Yes",
           id: "confirmationModal.yes",
         },
         noLabel: {
           defaultMessage: "No",
           id: "confirmationModal.no",
         },
         areYouSure: {
           defaultMessage: "Are you sure?",
           id: "confirmationModal.areYouSure",
         },
         title: {
           defaultMessage: "Your domains",
           id: "sideBar.title",
         },
         addButtonLabel: {
           defaultMessage: "Add",
           id: "sideBar.addButtonLabel",
         },
         addTitle: {
           defaultMessage: "Add domain",
           id: "sideBar.addTitle",
         },
         domainLabel: {
           defaultMessage: "Domain",
           id: "domainManagementModal.domainLabel",
         },
         domainValidation: {
           defaultMessage:
             "Please enter a domain only, not web site addresses or subdomains",
           id: "domainManagementModal.domainValidation",
         },
       });

export enum ModalFlowEnum {
  CREATE = "CREATE",
  DELETE = "DELETE",
  CONFIRM = "CONFIRM",
  BUSY = "BUSY",
}
